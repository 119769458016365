import country from "@/helpers/allCountriesCode.json";
import store from '@/state/store.js';
export default {
  data() {
    return {
      countries: country,
      formData: {
        country: [],
        local_rate: "0",
        international_rate: ""
      },
      rules: {
        country: [{
          required: true,
          message: "Country is required.",
          trigger: "blur"
        }],
        international_rate: [{
          required: true,
          message: "Enter a valid rate",
          trigger: "blur"
        }],
        local_rate: [{
          required: true,
          message: "Enter a valid rate",
          trigger: "blur"
        }]
      },
      dialogVisible: false,
      buttonloading: false
    };
  },
  methods: {
    openModal(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    },
    sendRates() {
      this.buttonloading = true;
      let payload = {
        country_name: this.formData.country[0].name,
        country_code: this.formData.country[0].dial_code,
        local_rate: this.formData.local_rate,
        international_rate: this.formData.international_rate,
        country_abbreviation: this.formData.country[0].abbreviation
      };
      store.dispatch('admin/international/sendInternationalRate', payload).then(response => {
        if (response.status === 200) {
          this.$notify({
            message: 'Rate sent successfully',
            type: 'success'
          });
          this.buttonloading = false;
          this.dialogVisible = false;
          this.$refs['ruleForm'].resetFields();
          this.$emit("refreshRatesTable");
        }
      }).catch(error => {
        this.$notify({
          message: 'Error sending rate',
          type: 'error'
        });
      }).finally(() => {
        this.buttonloading = false;
      });
    }
  }
};