import store from '@/state/store.js';
export default {
  data() {
    return {
      internationalRates: [],
      search: '',
      loadingTable: false,
      page: 1,
      pageSize: 20,
      total: 0
    };
  },
  computed: {
    tableData() {
      return this.internationalRates.filter(row => {
        return row.country_name.toLowerCase().includes(this.search.toLowerCase()) || row.country_code.toLowerCase().includes(this.search.toLowerCase()) || row.international_rate.toLowerCase().includes(this.search.toLowerCase()) || row.local_rate.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    displayTable() {
      return this.tableData.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
    isLoading() {
      return this.loadingTable;
    },
    mainPropKey() {
      this.fetchData();
      return this.$props.propKey;
    }
  },
  mounted() {
    this.fetchData();
  },
  props: {
    propKey: {
      type: Number,
      default: 0
    }
  },
  methods: {
    fetchData() {
      this.loadingTable = true;
      store.dispatch('admin/international/getInternationalRate').then(response => {
        if (response.status === 200) {
          this.internationalRates = response.data;
          this.loadingTable = false;
        }
      }).catch(error => {
        this.loadingTable = false;
        this, $notify({
          title: 'Error',
          message: 'Error fetching data',
          type: 'error'
        });
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    }
  }
};