var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "search-box"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search for country"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', {
    staticClass: "table-container"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    key: _vm.mainPropKey,
    attrs: {
      "data": _vm.displayTable,
      "height": "50vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "country_name",
      "label": "Country"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country_abbreviation",
      "label": "Abbreviation"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country_code",
      "label": "Dial Code"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "international_rate",
      "label": "International Rate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticStyle: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(scope.row.international_rate))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "local_rate",
      "label": "Local Rate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(_vm._s(scope.row.local_rate))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "updated_at",
      "label": "Updated On"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm.moment(scope.row.updated_at)))])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };