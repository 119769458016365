var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('h6', [_vm._v("Approve International Sender IDs")]), _c('div', {
    staticClass: "main-table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "data": _vm.internationalTemplates,
      "height": "60vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Sender ID",
      "prop": "sender_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_vm._v(" " + _vm._s(scope.row.sender_id.sender_id) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Phone Number",
      "prop": "user.phone"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Content",
      "prop": "body"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Assigned Rate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [scope.row.sender_id.type == 0 ? _c('span', [_vm._v("Unset")]) : _vm._e(), scope.row.sender_id.type == 1 ? _c('span', [_vm._v("Local")]) : _vm._e(), scope.row.sender_id.type == 2 ? _c('span', [_vm._v("International")]) : _vm._e()])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Selected Countries",
      "prop": "selected_countries"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Approved By",
      "prop": "approved_by.name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Date",
      "prop": "created_at",
      "width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.moment(scope.row.created_at).format("ll")) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Status",
      "prop": "status",
      "filters": [{
        text: 'Pending',
        value: 0
      }, {
        text: 'Approved',
        value: 1
      }, {
        text: 'Rejected',
        value: 2
      }, {
        text: 'Awaiting Third Party',
        value: 3
      }],
      "filter-method": _vm.filterTemplate,
      "filter-placement": "bottom-end"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [scope.row.status == 0 ? _c('span', {
          staticStyle: {
            "color": "#f7921c"
          }
        }, [_vm._v("Pending")]) : scope.row.status == 1 ? _c('span', {
          staticStyle: {
            "color": "#36d962"
          }
        }, [_vm._v("Approved")]) : scope.row.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#563df6"
          }
        }, [_vm._v("Awaiting Third Party")]) : _c('span', {
          staticStyle: {
            "color": "#f72f24"
          }
        }, [_vm._v("Rejected")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_c('el-dropdown', {
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.ApproveRejectTemplate
          }
        }, [_c('el-button', {
          attrs: {
            "id": "__edit"
          }
        }, [_c('img', {
          attrs: {
            "src": require("../../../assets/More Square.svg"),
            "alt": "img"
          }
        })]), _c('el-dropdown-menu', {
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              scope,
              type: 'Approve'
            }
          }
        }, [_vm._v("Approve")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              scope,
              type: 'Reject'
            }
          }
        }, [_vm._v("Reject")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              scope,
              type: 'Await Third Party'
            }
          }
        }, [_vm._v("Await Third Party Approval")])], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": "Select Rate Type",
      "visible": _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('span', [_c('el-form', {
    ref: "ruleForm",
    staticClass: "main-form",
    attrs: {
      "rules": _vm.form_rules,
      "model": _vm.rate_type,
      "label-position": "left"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Rate Type",
      "prop": "rate_type"
    }
  }, [_c('el-select', {
    model: {
      value: _vm.rate_type.rate_type,
      callback: function ($$v) {
        _vm.$set(_vm.rate_type, "rate_type", $$v);
      },
      expression: "rate_type.rate_type"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Local",
      "value": "1"
    }
  }), _c('el-option', {
    attrs: {
      "label": "International",
      "value": "2"
    }
  })], 1)], 1)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.buttonLoading
    },
    on: {
      "click": function ($event) {
        return _vm.sendData('ruleForm');
      }
    }
  }, [_vm._v("Confirm")])], 1)]), _c('el-dialog', {
    attrs: {
      "visible": _vm.confirmationModal,
      "title": "Confirmation"
    },
    on: {
      "update:visible": function ($event) {
        _vm.confirmationModal = $event;
      }
    }
  }, [_c('span', [_vm._v("Are you sure you want to "), _c('span', {
    class: [_vm.type == 'Approve' ? 'approved' : 'rejected']
  }, [_vm._v(_vm._s(_vm.type))]), _vm._v(" this template?")]), _c('div', {
    staticClass: "template-details__heading"
  }, [_c('h6', [_vm._v("Template Details")])]), _c('div', {
    staticClass: "template-details"
  }, [_c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Sender ID: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm._v(" " + _vm._s(_vm.userSenderID) + " ")])]), _c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Content: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm._v(_vm._s(_vm.payload.body))])]), _c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Assigned Rate: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm.rate_type.rate_type == 0 ? _c('span', [_vm._v("Unset")]) : _vm._e(), _vm.rate_type.rate_type == 1 ? _c('span', [_vm._v("Local")]) : _vm._e(), _vm.rate_type.rate_type == 2 ? _c('span', [_vm._v("International")]) : _vm._e()])])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.confirmationModal = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.confirmationLoading
    },
    on: {
      "click": _vm.confirmData
    }
  }, [_vm._v("Confirm")])], 1)]), _c('el-dialog', {
    attrs: {
      "visible": _vm.awaitThirdParty,
      "title": "Confirmation"
    },
    on: {
      "update:visible": function ($event) {
        _vm.awaitThirdParty = $event;
      }
    }
  }, [_c('span', [_vm._v("Are you sure you want to set this template to: "), _c('span', {
    class: [_vm.type == 'Approve' ? 'approved' : 'rejected']
  }, [_vm._v("Awaiting third party approval")])]), _c('div', {
    staticClass: "template-details__heading"
  }, [_c('h6', [_vm._v("Template Details")])]), _c('div', {
    staticClass: "template-details"
  }, [_c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Sender ID: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm._v(" " + _vm._s(_vm.userSenderID) + " ")])]), _c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Content: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm._v(_vm._s(_vm.payload.body))])]), _c('div', {
    staticClass: "template-details__item"
  }, [_c('strong', {
    staticClass: "template-details__item__label"
  }, [_vm._v("Assigned Rate: ")]), _c('span', {
    staticClass: "template-details__item__value"
  }, [_vm.rate_type.rate_type == 0 ? _c('span', [_vm._v("Unset")]) : _vm._e(), _vm.rate_type.rate_type == 1 ? _c('span', [_vm._v("Local")]) : _vm._e(), _vm.rate_type.rate_type == 2 ? _c('span', [_vm._v("International")]) : _vm._e()])])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.awaitThirdParty = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.confirmationLoading
    },
    on: {
      "click": _vm.confirmData
    }
  }, [_vm._v("Confirm")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };