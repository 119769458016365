import addRate from './addRate.vue';
import getRates from './getRates.vue';
import ApproveInternationalTemplates from './ApproveInternationalTemplates.vue';
export default {
  components: {
    addRate,
    getRates,
    ApproveInternationalTemplates
  },
  data() {
    return {
      propKey: 0,
      activeName: 'first'
    };
  },
  methods: {
    callRefreshTable() {
      return this.propKey += 1;
    },
    handleClick(tab, event) {}
  }
};