import moment from "moment";
import Storage from "../../../services/storage";
export default {
  data() {
    return {
      dialogVisible: false,
      payload: {},
      type: "",
      buttonLoading: false,
      tableLoading: false,
      rate_type: {
        rate_type: ""
      },
      form_rules: {
        rate_type: [{
          required: true,
          message: "Please select rate type",
          trigger: "change"
        }]
      },
      confirmationModal: false,
      confirmationLoading: false,
      awaitThirdParty: false,
      page: 1,
      pageSize: 20,
      total: 0,
      storage: new Storage()
    };
  },
  computed: {
    internationalTemplates() {
      return this.$store.getters["admin/international/internationalTemplates"].slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
    userSenderID() {
      if (this.payload.sender_id) {
        return this.payload.sender_id.sender_id ? this.payload.sender_id.sender_id : "";
      }
      // return this.payload.sender_id.sender_id ? this.payload.sender_id.sender_id : ''
    }
  },
  methods: {
    ApproveRejectTemplate({
      scope,
      type
    }) {
      this.payload = {
        ...scope.row,
        approve: type == "Approve" ? 1 : type === "Await Third Party" ? 3 : 2
      };
      this.type = type;
      if (type == "Approve") {
        this.dialogVisible = true;
      } else if (type == "Await Third Party") {
        this.awaitThirdParty = true;
      } else {
        this.confirmationModal = true;
      }
    },
    sendData(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // this.buttonLoading = true
          this.confirmationModal = true;
          // validate form
        } else {
          this.buttonLoading = false;
          return false;
        }
      });
    },
    confirmData() {
      let finalPayload = JSON.parse(JSON.stringify(this.payload));
      finalPayload.sender_id.type = this.rate_type.rate_type;
      const {
        created_at,
        updated_at,
        ...rest
      } = finalPayload;
      let payloadToBeSent = {
        ...rest,
        approved_by: this.storage.getSavedState('auth.admin').user_id
      };
      this.confirmationLoading = true;
      this.$store.dispatch("admin/international/ApproveOrReject", payloadToBeSent).then(() => {
        this.dialogVisible = false;
        this.buttonLoading = false;
        this.$message({
          type: "success",
          message: `Template has been toggled successfully!`
        });
        this.refetchData();
      }).catch(err => {
        this.$message({
          type: "error",
          message: err.response.data.message
        });
        this.refetchData();
      }).finally(() => {
        this.confirmationModal = false;
        this.buttonLoading = false;
        this.confirmationLoading = false;
        this.awaitThirdParty = false;
      });
    },
    refetchData() {
      this.tableLoading = true;
      this.$store.dispatch("admin/international/getInternationalTemplates").then(data => {}).finally(() => {
        this.tableLoading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    filterTemplate(value, row) {
      return row.status === value;
    }
  },
  mounted() {
    this.tableLoading = true;
    this.$store.dispatch("admin/international/getInternationalTemplates").then(data => {
      this.total = data.data.length;
    }).finally(() => {
      this.tableLoading = false;
    });
  }
};