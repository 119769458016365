var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-tabs', {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Add Rates",
      "name": "first"
    }
  }, [_c('div', {
    staticClass: "main-container"
  }, [_c('div', {
    staticClass: "left-side"
  }, [_c('p', [_vm._v("Add Rates")]), _c('addRate', {
    on: {
      "refreshRatesTable": _vm.callRefreshTable
    }
  })], 1), _c('div', {
    staticClass: "vertical-row"
  }), _c('div', {
    staticClass: "right-side"
  }, [_c('p', [_vm._v("View Rates")]), _c('getRates', {
    attrs: {
      "propKey": _vm.propKey
    }
  })], 1)])]), _c('el-tab-pane', {
    attrs: {
      "label": "Approve Templates",
      "name": "second"
    }
  }, [_c('ApproveInternationalTemplates')], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };